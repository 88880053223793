import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter, FieldConverterMap, ModelConverterInterface } from '@steelbuy/domain-model-converter';
import {
    ArrayOfMonetaryAmountFieldNames,
    ModelPrimaryKey,
    MonetaryAmount,
    MonetaryAmountFieldNames,
} from '@steelbuy/domain-model-types';

import { Country } from './AddressModel.enums';
import { WeightAndType } from './CartBuyerModel';
import { MaterialType, Product } from './MaterialModel.enums';
import { OrderBuyerModel, OrderBuyerModelConverter, OrderStatus } from './OrderBuyerModel';

export interface PrimeOrNonPrime {
    prime: boolean;
    nonPrime: boolean;
}

export interface StatusWithCount {
    count: number;
    status: OrderStatus;
}

export interface MaterialProductWithCount {
    product: Product;
    materialType: MaterialType;
    count: number;
}

export type CartOrderBuyerModel = {
    readonly id: ModelPrimaryKey;
    readonly steelBuyReferenceNumber: string;
    buyerOrderNumber: string;
    cartOrders: OrderBuyerModel[];
    totalValue: MonetaryAmount;
    totalValueVAT: MonetaryAmount;
    typeWithWeight: WeightAndType[];
    primeStatus: PrimeOrNonPrime;
    materialLocations: Country[];
    currencyCode: CurrencyCode;
    materialProductWithCount: MaterialProductWithCount[];
    taxPercent: number;
    taxValue: number;
    statusWithCount: StatusWithCount[];
};

export class CartOrderBuyerModelConverter extends ModelConverter<CartOrderBuyerModel> {
    override getMonetaryAmountFields(): Array<
        MonetaryAmountFieldNames<CartOrderBuyerModel> | ArrayOfMonetaryAmountFieldNames<CartOrderBuyerModel>
    > {
        return ['totalValue', 'totalValueVAT'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<CartOrderBuyerModel> {
        return {
            cartOrders: new OrderBuyerModelConverter() as ModelConverterInterface<OrderBuyerModel>,
        };
    }
}
