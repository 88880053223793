import { ReactNode, useEffect } from 'react';
import { toast, ToastContainer, Id, Zoom, ToastOptions } from 'react-toastify';
import { useWindowSize } from '@steelbuy/util';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Toast.scss';

const TOAST_MARGIN = 40;

export enum ToastContainerId {
    CRITICAL = 'critical',
}

export const CRITICAL_ERROR_ID = 'critical-error-id';

const getToastWidth = (mainLayoutEl: HTMLElement | null) => {
    if (!mainLayoutEl) {
        return 360;
    }
    return mainLayoutEl.clientWidth - TOAST_MARGIN * 2 + 1;
};

const getToastLeft = (mainLayoutEl: HTMLElement | null) => {
    if (!mainLayoutEl) {
        return 0;
    }
    return mainLayoutEl.offsetLeft - 1 + TOAST_MARGIN;
};

export const ToasterContainer = ({ containerId }: { containerId?: ToastContainerId }) => {
    const { width } = useWindowSize();

    useEffect(() => {
        let mainLayoutEl = document.getElementById('app-main-layout');

        const setToastWidth = () => {
            const root: HTMLElement | null = document.querySelector(':root');
            root?.style.setProperty('--toastify-toast-width', `${getToastWidth(mainLayoutEl)}px`);
            root?.style.setProperty('--toastify-toast-left', `${getToastLeft(mainLayoutEl)}px`);
        };

        const waitForMainLayout = () => {
            setTimeout(() => {
                mainLayoutEl = document.getElementById('app-main-layout');
                if (!mainLayoutEl) {
                    waitForMainLayout();
                } else {
                    setToastWidth();
                }
            }, 1000);
        };

        if (!mainLayoutEl) {
            waitForMainLayout();
        } else {
            setToastWidth();
        }
    }, [width]);
    return (
        <ToastContainer
            stacked
            position="bottom-left"
            autoClose={10000}
            transition={Zoom}
            hideProgressBar
            closeOnClick
            closeButton
            containerId={containerId}
        />
    );
};

const TOAST_METHODS = ['success', 'error', 'warn', 'info'] as const;

const CUSTOM_METHODS = ['criticalError'] as const;

const METHODS = [...TOAST_METHODS, ...CUSTOM_METHODS] as const;

type ToasterOptions = { cta?: { onClick?: () => void; label?: string; toastId?: number } } & ToastOptions;

type Toast = Record<typeof METHODS[number], (message: ReactNode, toastCTA?: ToasterOptions) => Id>;

const ToastCriticalError = ({
    message,
    onClick,
    label,
}: {
    message: ReactNode;
    onClick?: () => void;
    label?: string;
}) => (
    <div className="toast--critical-error">
        {message}
        <div className="button-container">
            <ButtonTertiaryOnLightM label={label} onClick={onClick} />
        </div>
    </div>
);

const getIconName = (method: typeof TOAST_METHODS[number]) => {
    switch (method) {
        case 'info':
            return IconIdentifier.INFO;

        case 'success':
            return IconIdentifier.WORKFLOW_SUCCESS;

        case 'warn':
            return IconIdentifier.ERROR;

        case 'error':
            return IconIdentifier.CANCEL;

        default:
            return IconIdentifier.INFO;
    }
};

const setToastPosition = () => {
    const mainLayoutEl = document.getElementById('app-main-layout');
    if (!mainLayoutEl) {
        return;
    }
    const root: HTMLElement | null = document.querySelector(':root') as HTMLElement;
    root?.style.setProperty('--toastify-toast-width', `${getToastWidth(mainLayoutEl)}px`);
    root?.style.setProperty('--toastify-toast-left', `${getToastLeft(mainLayoutEl)}px`);
};

export const toaster = METHODS.reduce((acc, method) => {
    acc[method] = (message: ReactNode, options?: ToasterOptions) => {
        setToastPosition();
        if (CUSTOM_METHODS.indexOf(method as typeof CUSTOM_METHODS[number]) > -1) {
            // switch (method) {
            //     // add cases as we add custom methods
            //     default:
            return toast.error(
                <ToastCriticalError message={message} onClick={options?.cta?.onClick} label={options?.cta?.label} />,
                {
                    className: `toast-wrapper toast-wrapper--error`,
                    icon: <Icon name={getIconName('error')} />,
                    autoClose: false,
                    containerId: ToastContainerId.CRITICAL,
                    toastId: `${CRITICAL_ERROR_ID}-${options?.cta?.toastId ?? 0}`,
                }
            );
            // }
        }
        const toastMethod = method as typeof TOAST_METHODS[number];
        return toast[toastMethod](message, {
            className: `toast-wrapper toast-wrapper--${toastMethod}`,
            icon: <Icon name={getIconName(toastMethod)} />,
            autoClose: options?.autoClose,
        });
    };
    return acc;
}, {} as Toast);
