import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isCartOrder, useMyPurchaseOrders } from '@steelbuy/data-access';

import { ListingBuyerOrderTeaser, ListingBuyerCartOrderTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    LoadingSpinner,
    LoadingStatus,
    MarketingBannerDashboardBuy,
} from '@steelbuy/ui-primitive';
import { useMyOrdersContext } from './MyOrdersContext';
import { MyOrdersEmptyState } from './MyOrdersEmptyState';
import { MyOrdersFilter } from './MyOrdersFilter';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { getScrollTopMainLayout, scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { PageHeader } from '../page-header/PageHeader';

import './MyOrders.scss';

export const MyOrders = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    const { filter, setScrollPosition, scrollPosition } = useMyOrdersContext();
    const statusFilter = filter.length ? { status: filter.join('|') } : undefined;

    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, total } = useMyPurchaseOrders({
        filterCriteria: statusFilter,
    });
    const navigate = useNavigate();
    const noResults = filter.length > 0 && data?.length === 0;
    const noOrders = data?.length === 0 && filter.length === 0;

    useEffect(() => {
        scrollMainLayout(0, scrollPosition);
        setScrollPosition(0);
    }, []);

    const handleScroll = () => {
        setScrollPosition(getScrollTopMainLayout());
    };

    const renderOrders = () => {
        if (isLoading) {
            return (
                <div className="my-orders-loading-spinner">
                    <LoadingSpinner fullHeight={false} />
                </div>
            );
        }
        return (
            <>
                {noOrders ? (
                    <div className="my-orders__no-results">
                        <MarketingBannerDashboardBuy
                            header={t('application.myOrders.bannerBuy.header')}
                            subHeader={t('application.myOrders.bannerBuy.subHeader')}
                            buttonLabel={t('application.myOrders.bannerBuy.buttonLabel')}
                            descriptionText={t('application.myOrders.bannerBuy.description', { returnObjects: true })}
                            onClick={() => navigate(RoutePath.SEARCH_LISTINGS)}
                        />
                    </div>
                ) : (
                    <DataHeader headingValue={t('application.myOrders.numberOfOrders', { count: total })}>
                        <MyOrdersFilter />
                    </DataHeader>
                )}

                <div className="my-orders__orders">
                    {data?.map((order) => (
                        <div key={isCartOrder(order) ? `${order.steelBuyReferenceNumber}` : `${order.id}`}>
                            {isCartOrder(order) ? (
                                <Card
                                    isClickable
                                    url={createRouteUrl(RoutePath.MY_ORDERS_CART, [
                                        'cartRefNo',
                                        order.steelBuyReferenceNumber,
                                    ])}
                                    onClick={handleScroll}
                                >
                                    <ListingBuyerCartOrderTeaser order={order} />
                                </Card>
                            ) : (
                                <Card
                                    isClickable
                                    url={createRouteUrl(RoutePath.MY_ORDERS_DETAILS, ['orderId', order.id])}
                                    onClick={handleScroll}
                                >
                                    <ListingBuyerOrderTeaser order={order} />
                                </Card>
                            )}
                        </div>
                    ))}
                </div>
                {hasNextPage && (
                    <div className="search-results__load-more">
                        <ButtonTertiaryOnLightM
                            onClick={() => fetchNextPage()}
                            label={t('uiDomain:commonList.loadMore')}
                            loadingStatus={isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    </div>
                )}
                {noResults && <MyOrdersEmptyState />}
            </>
        );
    };

    return (
        <div className="my-orders-view">
            <PageHeader pageTitle={t('application.myOrders.header')} />
            <TableLayout>
                <div className="my-orders">{renderOrders()}</div>
            </TableLayout>
        </div>
    );
};
