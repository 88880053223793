import { useQuery } from '@tanstack/react-query';
import { CartOrderBuyerModel, CartOrderBuyerModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';

interface OrderViewsOptionsProps {
    cartRefNo: string;
}
export const MY_PURCHASE_CART_ORDER = 'purchase-cart-orders';

const cartModelConverter = new CartOrderBuyerModelConverter();

const getCartPODetails = async (cartRefNo: string) => {
    const { data } = await axios.get<CartOrderBuyerModel>(`/api/universal/purchase-orders/cart-orders/${cartRefNo}`);

    const cartData = cartModelConverter.toViewModel(data as unknown as ApiModel<CartOrderBuyerModel>);

    return cartData;
};

export const useMyPurchaseCartOrders = ({ cartRefNo }: OrderViewsOptionsProps) =>
    useQuery({
        queryKey: [MY_PURCHASE_CART_ORDER, cartRefNo],
        queryFn: () => getCartPODetails(cartRefNo),
    });
