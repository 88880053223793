import { useTranslation } from 'react-i18next';
import { EmptyState, FillHeightContainer } from '@steelbuy/ui-primitive';
import { useMyOrdersContext } from './MyOrdersContext';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

export const MyOrdersEmptyState = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { reset } = useMyOrdersContext();
    return (
        <FillHeightContainer className="my-orders__empty-state-container" extraPadding={FOOTER_HEIGHT} minHeight={220}>
            <EmptyState
                caption={t('uiDomain:commonList.noListCaption')}
                description={t('application.myOrders.noResultsDescription')}
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                button={{
                    label: t('application.myOrders.noOrdersCallToAction'),
                    onClick: () => {
                        reset();
                    },
                }}
            />
        </FillHeightContainer>
    );
};
