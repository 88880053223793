import { useTranslation } from 'react-i18next';
import { ModalAccessor } from '@steelbuy/modal-dialog';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const OfferInProgressModal = ({
    offerInProgressModal,
    handleBuyNow,
    header,
    buttonLabel,
}: {
    offerInProgressModal: ModalAccessor;
    handleBuyNow: () => void;
    header: string;
    buttonLabel: string;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <ConfirmCancelModel
            modalAccessor={offerInProgressModal}
            header={header}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Neutral.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={buttonLabel}
            onSubmit={handleBuyNow}
            isSubmitting={false}
            isDanger={false}
            onClose={offerInProgressModal.hide}
        />
    );
};
