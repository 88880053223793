import { useTranslation } from 'react-i18next';
import { EmptyState } from '@steelbuy/ui-primitive';

import './MyAlertsNeedMore.scss';

export const MyAlertsNeedMore = () => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="my-alerts__need-more">
            <EmptyState
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                caption={t('myAlertsNeedMore.caption')}
                description={t('myAlertsNeedMore.description')}
            />
        </div>
    );
};
