import { useEffect, useState } from 'react';

const APP_MAIN_LAYOUT_ID = 'app-main-layout';

export const useScrollPosition = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const element = document.getElementById(APP_MAIN_LAYOUT_ID);

        if (element) {
            const updateScrollPosition = () => {
                setScrollY(element.scrollTop);
            };
            element.addEventListener('scroll', updateScrollPosition);
            updateScrollPosition();

            return () => element.removeEventListener('scroll', updateScrollPosition);
        }

        return undefined;
    }, []);

    return { scrollY };
};
