import { RefObject, useEffect, useState } from 'react';

export const useCursorPosition = (elementRef: RefObject<HTMLInputElement | HTMLTextAreaElement>) => {
    const [cursor, setCursor] = useState<number | null>(null);

    useEffect(() => {
        if (cursor) {
            elementRef.current?.setSelectionRange(cursor, cursor);
        }
    }, [cursor]);

    return {
        setCursor,
    };
};
