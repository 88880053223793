import classNames from 'classnames';
import { ReactNode } from 'react';

export const ListingTeaser = ({
    children,
    threeColumns,
    isCartCheckout,
}: {
    children: ReactNode;
    threeColumns?: boolean;
    isCartCheckout?: boolean;
}) => (
    <div
        className={classNames('listing-teaser', {
            'listing-teaser--three-columns': isCartCheckout ? false : threeColumns,
            'listing-teaser--cart-checkout': isCartCheckout,
        })}
    >
        {children}
    </div>
);
