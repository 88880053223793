import { AnyAction, combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
    addressEntitySlice,
    addressEntityStoreName,
    fooCollectionSlice,
    fooCollectionStoreName,
    fooCreateEntitySlice,
    fooCreateEntityStoreName,
    fooEntitySlice,
    fooEntityStoreName,
    historicalAddressEntitySlice,
    historicalAddressEntityStoreName,
    invoiceAddressCollectionSlice,
    invoiceAddressCollectionStoreName,
    listingBuyerAlertCollectionSlice,
    listingBuyerAlertCollectionStoreName,
    listingBuyerAlertSearchSlice,
    listingBuyerAlertSearchStoreName,
    listingBuyerEntitySlice,
    listingBuyerEntityStoreName,
    listingBuyerSearchSlice,
    listingBuyerSearchStoreName,
    listingSellerCollectionSlice,
    listingSellerCollectionStoreName,
    listingSellerDraftCollectionSlice,
    listingSellerDraftCollectionStoreName,
    listingSellerDraftEntitySlice,
    listingSellerDraftEntityStoreName,
    listingSellerEntitySlice,
    listingSellerEntityStoreName,
    orderBuyerCreateEntitySlice,
    orderBuyerCreateEntityStoreName,
    organisationEntitySlice,
    organisationEntityStoreName,
    userCollectionSlice,
    userCollectionStoreName,
    userEntitySlice,
    userEntityStoreName,
    userSelfEntitySlice,
    userSelfEntityStoreName,
    warehouseAddressCollectionSlice,
    warehouseAddressCollectionStoreName,
} from '@steelbuy/data-provider';

const combinedReducer = combineReducers({
    [addressEntityStoreName]: addressEntitySlice.reducer,
    [fooCollectionStoreName]: fooCollectionSlice.reducer,
    [fooCreateEntityStoreName]: fooCreateEntitySlice.reducer,
    [fooEntityStoreName]: fooEntitySlice.reducer,
    [historicalAddressEntityStoreName]: historicalAddressEntitySlice.reducer,
    [invoiceAddressCollectionStoreName]: invoiceAddressCollectionSlice.reducer,
    [userSelfEntityStoreName]: userSelfEntitySlice.reducer,
    [organisationEntityStoreName]: organisationEntitySlice.reducer,
    [listingBuyerAlertCollectionStoreName]: listingBuyerAlertCollectionSlice.reducer,
    [listingBuyerSearchStoreName]: listingBuyerSearchSlice.reducer,
    [listingBuyerAlertSearchStoreName]: listingBuyerAlertSearchSlice.reducer,
    [listingBuyerEntityStoreName]: listingBuyerEntitySlice.reducer,
    [listingSellerCollectionStoreName]: listingSellerCollectionSlice.reducer,
    [listingSellerDraftCollectionStoreName]: listingSellerDraftCollectionSlice.reducer,
    [listingSellerDraftEntityStoreName]: listingSellerDraftEntitySlice.reducer,
    [listingSellerEntityStoreName]: listingSellerEntitySlice.reducer,
    [orderBuyerCreateEntityStoreName]: orderBuyerCreateEntitySlice.reducer,
    [warehouseAddressCollectionStoreName]: warehouseAddressCollectionSlice.reducer,
    [userCollectionStoreName]: userCollectionSlice.reducer,
    [userEntityStoreName]: userEntitySlice.reducer,
});

export type RootState = ReturnType<typeof combinedReducer> | undefined;
export type AppStore = ReturnType<typeof setupStore>;

export const UNAUTHENTICATE_SIGNAL = '@@unauth';

export const rootReducer = (rootState: RootState, action: AnyAction) => {
    let state = rootState;
    if (action.type === UNAUTHENTICATE_SIGNAL) {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const setupStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: [...getDefaultMiddleware()],
    });
