import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { MY_PURCHASE_ORDER } from '@steelbuy/data-access';
import { MyOrdersContextProvider } from '../../components/my-orders/MyOrdersContext';

export const MyOrdersView = () => {
    const queryClient = useQueryClient();
    useEffect(
        () => () => {
            queryClient.removeQueries({ queryKey: [MY_PURCHASE_ORDER] });
        },
        []
    );

    return (
        <MyOrdersContextProvider>
            <Outlet />
        </MyOrdersContextProvider>
    );
};
