import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { OrderBuyerModelConverter, OrderBuyerModel } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';

interface OrderViewsOptions<Model> {
    orderId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}
export const ORDER_DETAILS_CACHE_KEY = 'purchase-order-details';

const modelConverter = new OrderBuyerModelConverter();

const getPODetails = async (orderId: string) => {
    const { data } = await axios.get<OrderBuyerModel>(`/api/universal/purchase-orders/${orderId}`);
    const orderModel = modelConverter.toViewModel(data as unknown as ApiModel<OrderBuyerModel>);
    return orderModel;
};

export const useMyPurchaseOrderDetails = ({ orderId, queryOptions }: OrderViewsOptions<OrderBuyerModel>) =>
    useQuery({
        queryKey: [ORDER_DETAILS_CACHE_KEY, orderId],
        queryFn: () => getPODetails(orderId),
        ...queryOptions,
    });
