import { ButtonHTMLAttributes } from 'react';

import { buildClassStringFromClassMap } from '@steelbuy/util';

import { LoadingStatus } from '../../button-loading-spinner/LoadingStatus.enum';
import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './ButtonTertiaryOnDarkS.scss';
import { LoadingSpinner } from '../../loading-spinner/LoadingSpinner';
import { LoadingSpinnerSize } from '../../loading-spinner/LoadingSpinner.enums';

export type ButtonTertiaryOnDarkSProps = {
    label?: string;
    icon?: IconIdentifier;
    loadingStatus?: LoadingStatus;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonTertiaryOnDarkS = (props: ButtonTertiaryOnDarkSProps) => {
    const { label, icon, type = 'button', loadingStatus = LoadingStatus.IDLE, ...rest } = props;

    const buttonTertiaryOnDarkSClassMap = {
        'button-tertiary-on-dark-s': true,
        'button-tertiary-on-dark-s__only-icon': !label,
    };

    const buttonTertiaryOnDarkSContentClassMap = {
        'button-tertiary-on-dark-s__content': true,
        'button-tertiary-on-dark-s__content--hidden': loadingStatus === LoadingStatus.PENDING,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return <span className="button-tertiary-on-dark-s__content__label">{label}</span>;
    };

    const renderIcon = () => {
        if (icon === undefined) {
            return null;
        }
        return <Icon name={icon} />;
    };

    const renderLoadingSpinner = () =>
        loadingStatus === LoadingStatus.PENDING && (
            <LoadingSpinner symbolSize={LoadingSpinnerSize.SMALL} fullHeight={false} />
        );

    return (
        <button
            type={type}
            className={buildClassStringFromClassMap(buttonTertiaryOnDarkSClassMap)}
            disabled={loadingStatus === LoadingStatus.PENDING}
            {...rest}
        >
            <span className={buildClassStringFromClassMap(buttonTertiaryOnDarkSContentClassMap)}>
                {renderLabel()}
                {renderIcon()}
            </span>
            {renderLoadingSpinner()}
        </button>
    );
};
