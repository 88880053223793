import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressIdentifier } from './CardContentAddressDetails.enums';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { InputRadioButton } from '../input-radio-button/InputRadioButton';
import { InputRadioButtonGroup, InputRadioButtonGroupAlign } from '../input-radio-button-group/InputRadioButtonGroup';
import { InputTextarea } from '../input-textarea/InputTextarea';

import './CardContentAddressDetails.scss';

interface CardContentAddressDetailsProps {
    heading: string;
    type: AddressIdentifier;
    attributeLabel?: string;
    additionalPlaceholder?: string;
    additionalText?: string;
    additionalTextLabel?: string;
    onChangeAdditionalText?: (value: string) => void;
    chooseOtherAddressButtonLabel?: string;
    onChooseOtherAddressButtonClick?: () => void;
    readonly?: boolean;
    children: ReactNode;
    isCartCheckout?: boolean;
}

export const CardContentAddressDetails = ({
    additionalText,
    additionalTextLabel,
    children,
    chooseOtherAddressButtonLabel,
    heading,
    attributeLabel,
    onChangeAdditionalText,
    onChooseOtherAddressButtonClick,
    readonly,
    type,
    additionalPlaceholder,
    isCartCheckout,
}: CardContentAddressDetailsProps) => {
    const { t } = useTranslation('translation');

    const renderChooseAddress = () => (
        <>
            <header className="card-content-address-details__wrapper__header">
                {t('application.checkoutDeliveryOption.headerLabel')}
            </header>
            <span className="card-content-address-details__wrapper__description">
                {t('application.checkoutDeliveryOption.headerDescriptionLabel')}
            </span>
            <InputRadioButtonGroup align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}>
                <article className="card-content-address-details__wrapper__button-group__group">
                    <InputRadioButton
                        value={t('application.checkoutDeliveryOption.singleAddressLabel', 'Single address')}
                        id="radio-button-single-address"
                        name="radio-button-group-address"
                        checked
                    />
                    <div className="card-content-address-details__wrapper__button-group__group__helper-text-single">
                        {t('application.checkoutDeliveryOption.singleAddressDescriptionLabel')}
                    </div>
                </article>
                <article className="card-content-address-details__wrapper__button-group__upfront-group">
                    <InputRadioButton
                        value={t('application.checkoutDeliveryOption.multipleAddressLabel', 'Multiple address')}
                        id="radio-button-credit-multiple-address"
                        name="radio-button-group-address"
                        disabled
                    />
                    <div className="card-content-address-details__wrapper__button-group__upfront-group__helper-text-multiple">
                        {t('application.checkoutDeliveryOption.multipleAddressDescriptionLabel')}
                    </div>
                </article>
            </InputRadioButtonGroup>
        </>
    );

    const renderChooseOtherAddressButton = () => {
        if (readonly || type === AddressIdentifier.BILLING_ADDRESS) {
            return null;
        }

        return (
            <ButtonTertiaryOnLightM label={chooseOtherAddressButtonLabel} onClick={onChooseOtherAddressButtonClick} />
        );
    };

    const renderAdditionalTextInput = () => {
        if (type === AddressIdentifier.BILLING_ADDRESS) {
            return null;
        }

        return (
            <InputTextarea
                label={additionalTextLabel}
                onChange={onChangeAdditionalText}
                readOnly={readonly}
                value={additionalText}
                placeholder={additionalPlaceholder}
            />
        );
    };

    return (
        <article className="card-content-address-details">
            <article className="card-content-address-details__wrapper">
                {type === AddressIdentifier.DELIVERY_ADDRESS && isCartCheckout && renderChooseAddress()}
                <header className="card-content-address-details__wrapper__header">{heading}</header>
                {attributeLabel && (
                    <article className="card-content-address-details__wrapper__label">{attributeLabel}</article>
                )}
                <article className="card-content-address-details__wrapper__addressdata">
                    {children}
                    {renderChooseOtherAddressButton()}
                </article>
            </article>
            {renderAdditionalTextInput()}
        </article>
    );
};
