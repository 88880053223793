import { useTranslation } from 'react-i18next';
import { Country, PrimeOrNonPrime, StatusWithCount } from '@steelbuy/domain-model';
import { BadgeState, BadgeVariant } from '@steelbuy/ui-primitive';
import { ListingTeaserCartLocations } from './ListingTeaserCartLocations';
import { ListingTeaserCartQuality } from './ListingTeaserCartQuality';
import { getBadgeVariant } from '../order-status-badge-variant/OrderStatusBadgeVariant';

type ListingTeaserCartContainerProps = {
    primeStatus: PrimeOrNonPrime;
    materialLocations: Country[];
    statusWithCount: StatusWithCount[];
};
export const ListingTeaserCartContainer = ({
    primeStatus,
    materialLocations,
    statusWithCount,
}: ListingTeaserCartContainerProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel', 'translation']);

    return (
        <div className="listing-teaser__container">
            <ListingTeaserCartQuality primeStatus={primeStatus} />
            <div className="listing-teaser__container__location-and-status">
                <div className="listing-teaser__container__location-and-status__wrapper">
                    <ListingTeaserCartLocations materialLocations={materialLocations} />
                </div>
                <div className="listing-teaser__container__location-and-status__wrapper">
                    {statusWithCount.map(({ count, status }) => {
                        const statusLabel = t(`orderStatus.${status}`);

                        return (
                            <BadgeState
                                key={status}
                                label={`${statusLabel} (${count})`}
                                variant={getBadgeVariant(status) ?? BadgeVariant.NEUTRAL}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
