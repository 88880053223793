import { PrimeOrNonPrime } from '@steelbuy/domain-model';
import { IconIdentifier, Tag, TagVariant } from '@steelbuy/ui-primitive';

interface ListingTeaserCartQualityProps {
    primeStatus: PrimeOrNonPrime;
}

export const ListingTeaserCartQuality = ({ primeStatus }: ListingTeaserCartQualityProps) => {
    if (!primeStatus) return null;

    const { prime, nonPrime } = primeStatus;

    return (
        <div className="listing-teaser__container__prime-status">
            {prime && <Tag label="Prime" icon={IconIdentifier.PRIME} variant={TagVariant.PRIME} />}
            {nonPrime && <Tag label="Non-Prime" variant={TagVariant.NON_PRIME} />}
        </div>
    );
};
