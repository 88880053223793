import { useTranslation } from 'react-i18next';
import { CurrencyCode, formatCurrency } from '@steelbuy/currency';
import { WeightAndType } from '@steelbuy/domain-model';
import { ButtonCallToAction } from '@steelbuy/ui-primitive';
import './MyCartWeightType.scss';

interface MyCartWeightTypeProps {
    typeWithWeight: WeightAndType[];
    noOfEntries: number;
    totalValue: number;
    currencyCode: CurrencyCode;
    canCheckout: boolean;
    navigateCheckout: () => void;
}

export const MyCartWeightType = ({
    typeWithWeight,
    noOfEntries,
    totalValue,
    currencyCode,
    canCheckout,
    navigateCheckout,
}: MyCartWeightTypeProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);

    return (
        <div className="my-cart-weight-type">
            <div className="my-cart-weight-type__label">{t('uiDomain:listingBuyerDetailsPrice.cardTitle')}</div>
            <div className="my-cart-weight-type__subcontainer">
                <div className="my-cart-weight-type__subcontainer__label">{t('uiDomain:cart.subtotalWeight')}</div>
                {typeWithWeight.map((entry) => (
                    <ul key={entry.type} className="my-cart-weight-type__subcontainer__type">
                        <li>{t(`domainModel:material.materialType.value.${entry.type}`)}</li>
                        <span className="my-cart-weight-type__subcontainer__type__weight">
                            {t(`domainModel:material.tradeUnit.value.${entry.tradeUnit}.quantity`, {
                                count: entry.weight,
                            })}
                        </span>
                    </ul>
                ))}
                <div className="subtotal">
                    <span className="subtotal__label">
                        <span>{t('uiDomain:cart.subtotal')}</span>
                        <br />
                        <span>
                            <span>{t('uiDomain:cart.exclVat')} </span>
                            <span className="subtotal__label__items">
                                {t('uiDomain:cart.item', {
                                    count: noOfEntries,
                                })}
                            </span>
                        </span>
                    </span>
                    <span className="subtotal__label__price">
                        {formatCurrency(navigator.language, totalValue, currencyCode)}
                    </span>
                </div>
            </div>
            <ButtonCallToAction
                label={t('translation:application.checkout.header.title')}
                onClick={navigateCheckout}
                disabled={!canCheckout}
            />
        </div>
    );
};
