import { useParams } from 'react-router-dom';
import { WarehouseAddressCollectionDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { MyOrderDetails } from '../../components/my-order-details/MyOrderDetails';
import { MyOrderDetailsNotFound } from '../../components/my-order-details/MyOrderDetailsNotFound';

export const MyOrdersDetailsView = () => {
    const { orderId = null } = useParams();
    if (!orderId) return null;

    const handleError = (error: Error) => {
        if (error instanceof NotFoundError) {
            return <MyOrderDetailsNotFound />;
        }

        return null;
    };

    return (
        <ErrorHandler errorComponent={handleError}>
            <WarehouseAddressCollectionDataProvider>
                <MyOrderDetails orderId={orderId} />
            </WarehouseAddressCollectionDataProvider>
        </ErrorHandler>
    );
};
