import { FetchNextPageOptions, InfiniteQueryObserverResult, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    CartProductType,
    MY_CART_DETAILS,
    ResponseModel,
    fetchDetails,
    handleCartError,
    updateCartCache,
    useAddItemToCart,
    useCartDetails,
} from '@steelbuy/data-access';
import { ListingBuyerSearchResultModel, PackageBuyerModel, CartBuyerModel } from '@steelbuy/domain-model';
import { useModal } from '@steelbuy/modal-dialog';
import { SearchResults } from '@steelbuy/ui-domain';
import { Separator, SeparatorIdentifier, LoadingSpinner, toaster } from '@steelbuy/ui-primitive';
import { ListingAlertNotification } from './ListingAlertNotification';
import { ListingSearchSummary } from './ListingSearchSummary';
import { SearchResultsEmptyState } from './SearchResultsEmptyState';
import { SearchResultsPageHeader } from './SearchResultsPageHeader';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { AddToCartConfirmModal } from '../add-to-cart-confirm-modal/AddToCartConfirmModal';
import { getScrollTopMainLayout, scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { useSearchFormDataContext } from '../listing-search/SearchFormDataContext';
import { useSearchResultsContext } from '../listing-search/SearchResultsContext';

type SearchResultsLayoutProps<T> = {
    isLoading: boolean;
    isPackage?: boolean;
    data?: ReadonlyArray<T>;
    hasNextPage?: boolean;
    total?: number;
    isFetchingNextPage: boolean;
    fetchNextPage: (
        options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<ResponseModel<T[]>, unknown>>;
};
export const SearchResultsLayout = <T extends ListingBuyerSearchResultModel | PackageBuyerModel>({
    isLoading,
    data,
    isPackage,
    hasNextPage,
    total,
    fetchNextPage,
    isFetchingNextPage,
}: SearchResultsLayoutProps<T>) => {
    const searchFormData = useSearchFormDataContext().getSearchFormData();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const { sortCriteria, setSortCriteria, setScrollPosition, scrollPosition } = useSearchResultsContext();
    const checkoutContext = useCheckoutContext();
    const addItemToCartMutation = useAddItemToCart();
    const [cartData, setCartData] = useState<CartBuyerModel>();
    const [listingIdAddingToCart, setListingIdAddingToCart] = useState<string>('');
    const [isBuyingListingId, setIsBuyingListingId] = useState('');

    const handleBackToSearch = (): void => {
        navigate(RoutePath.SEARCH_LISTINGS);
    };
    const { refetch } = useCartDetails({ queryOptions: { enabled: false } });

    const queryClient = useQueryClient();

    const openAddToCartModal = useModal();

    const handleAddToCart = async (id: string): Promise<void> => {
        setListingIdAddingToCart(id);
        addItemToCartMutation.mutate(
            { productId: id, productType: isPackage ? CartProductType.PACKAGE : CartProductType.LISTING },
            {
                onSuccess: (response) => {
                    setCartData(response);
                    openAddToCartModal.show();
                    setListingIdAddingToCart('');
                    updateCartCache(queryClient, [MY_CART_DETAILS], response);
                },
                onError: (e: unknown) => {
                    setListingIdAddingToCart('');
                    handleCartError(e, refetch, t);
                },
            }
        );
    };

    const handleCheckout = async (id: string) => {
        checkoutContext.initializeWithListingId(id);
        setIsBuyingListingId(id);
        const listing = await fetchDetails(id, isPackage);
        if (listing.canCheckout) {
            navigate(isPackage ? RoutePath.PACKAGE_SEARCH_CHECKOUT : RoutePath.SEARCH_LISTINGS_CHECKOUT, {
                state: isPackage ? RoutePath.PACKAGE_SEARCH_RESULTS : RoutePath.SEARCH_LISTINGS_RESULTS,
            });
        } else {
            toaster.warn(t('application.addToCartConfirmModal.insufficientCredit'));
        }
        setIsBuyingListingId('');
    };

    useEffect(() => {
        scrollMainLayout(0, scrollPosition);
        setScrollPosition(0);
    }, []);
    return (
        <div className="listing-search-results">
            <SearchResultsPageHeader handleBackToSearch={handleBackToSearch} />
            <TableLayout>
                <ListingSearchSummary isPackage={isPackage} />
                <Separator separatorType={SeparatorIdentifier.ON_LIGHT} />
                {!isPackage && <ListingAlertNotification />}

                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading ? (
                    <LoadingSpinner />
                ) : data?.length ? (
                    <SearchResults<T>
                        searchFormData={searchFormData}
                        totalResults={total}
                        results={data}
                        hasNext={hasNextPage}
                        handleLoadMore={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        sortCriteria={sortCriteria}
                        handleSortChange={setSortCriteria}
                        pathToListingDetails={(listingId) => {
                            const route = isPackage
                                ? RoutePath.PACKAGE_SEARCH_DETAILS
                                : RoutePath.SEARCH_LISTING_DETAILS;
                            const routeParams = isPackage ? 'packageId' : 'listingId';
                            return createRouteUrl(route, [routeParams, listingId]);
                        }}
                        onClickListing={() => {
                            setScrollPosition(getScrollTopMainLayout());
                        }}
                        isPackage={isPackage}
                        handleCheckout={handleCheckout}
                        onClickCartButton={handleAddToCart}
                        selectedListingId={listingIdAddingToCart}
                        isBuyingListingId={isBuyingListingId}
                    />
                ) : (
                    <SearchResultsEmptyState handleBackToSearch={handleBackToSearch} />
                )}
                {cartData && <AddToCartConfirmModal modalAccessor={openAddToCartModal} cartData={cartData} />}
            </TableLayout>
        </div>
    );
};
