import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { CartBuyerModel } from '@steelbuy/domain-model';
import { toaster } from '@steelbuy/ui-primitive';

export const checkListingAlreadyInCart = (cartData: CartBuyerModel, id: string) =>
    cartData?.cartEntries?.some((entry: { product: { id: string } }) => entry.product.id === id);

export const handleCartError = (
    e: unknown,
    refetch: (
        options?: RefetchOptions & RefetchQueryFilters<CartBuyerModel>
    ) => Promise<QueryObserverResult<CartBuyerModel, unknown>>,
    t: TFunction<'translation'>
) => {
    if (e instanceof AxiosError && e.response && e.response.data.message === 'Product already added in cart!!') {
        toaster.warn(t('application.addToCartConfirmModal.alreadyInCart'));
        refetch();
    } else if (e instanceof AxiosError && e.response?.data.errorCode === '2004') {
        toaster.warn(t('application.addToCartConfirmModal.insufficientCredit'));
    } else toaster.warn(t('application.addToCartConfirmModal.addToCartFailed'));
};

let hideCartExpiryToast = false;

export const setHideCartExpiryToast = (hide: boolean) => {
    hideCartExpiryToast = hide;
};

export const getHideCartExpiryToast = () => hideCartExpiryToast;

export type OrderModel = { purchaseOrderNumber: string; steelbuyReferenceNumber: string };
