export type CartSummaryHeaderProps = {
    symbol?: string;
    firstLine: string;
    secondLine: string;
    thirdLine: string;
    fourthLine?: string;
};

export const CartSummaryHeader = (props: CartSummaryHeaderProps) => {
    const { symbol, firstLine, secondLine, thirdLine, fourthLine } = props;

    return (
        <div className="cart-summary__header">
            {symbol && (
                <div className="cart-summary__icon">
                    <svg className="cart-summary__header__symbol">
                        <use href={`/assets/lib-ui-primitive/icons/icon.svg#${symbol}`} />
                    </svg>
                </div>
            )}

            <div className="cart-summary__header__product">
                <p className="cart-summary__header__product__first-line">{firstLine}</p>
                <div className="cart-summary__header__product__details">
                    <span className="cart-summary__header__product__second-line">{secondLine}</span>
                    <span className="cart-summary__header__product__third-line">{thirdLine}</span>
                    <span className="cart-summary__header__product__fourth-line">{fourthLine}</span>
                </div>
            </div>
        </div>
    );
};
