import { ListingStatus, OfferListingStatus, PricingUnit, Product, RFQStatus } from '@steelbuy/domain-model';
import { AddressIdentifier } from '@steelbuy/ui-primitive';
import { Steps } from '../app/components/create-listing/CreateListingContextUtil';
import { RFQStep } from '../app/components/create-rfq/createRFQUtil';

export default {
    constants: {
        primaryAddress: {
            line1: "Friar's Gate",
            line2: '(Third Floor)',
            line3: '1011 Stratford Road',
            line4: 'Shirley',
            line5: 'Solihull',
            line6: 'West Midlands',
            country: 'England',
            postcode: 'B90 4BN',
        },
        companyName: 'SteelBuy Limited',
        companyNumber: '01914559',
        customerServiceTel: '+44 (0) 121 713 7280',
        emailSupport: 'support@steel-buy.com',
        registerLink: 'https://steel-buy.com/account-registration/',
        emailInfo: 'info@steel-buy.com',
        tkCompanyName: 'thyssenkrupp Materials (UK) Ltd',
        trademarkNumber: 'UK00003639459',
        vatNumber: 'GB 695 9678 44',
        website: 'steel-buy.com',
        marketplace: 'steelbuy.app',
    },
    application: {
        acceptabelUsePolicy: {
            headerTitle: 'Acceptable Use Policy',
        },
        account: {
            headerTitle: 'Account',
            headerButtonAddNewUser: 'Add new User',
            tabMyAccount: 'My Account',
            tabOrganisation: 'Organisation',
            tabUserManagement: 'User Management',
        },
        accountAddAddress: {
            errorMessage: 'Failed to add address. Please try again later.',
            pageTitle: 'Add Warehouse Address',
            previousPageTitle: 'Organisation Account',
        },
        accountEditAddress: {
            errorMessage: 'Failed to edit address. Please try again later.',
            pageTitleInvoiceAddress: 'Edit Invoice Address',
            pageTitleWarehouseAddress: 'Edit Warehouse Address',
            previousPageTitle: 'Organisation Account',
        },
        accountEditAddressNotFound: {
            notFoundMessage: 'This address could not be found.',
            pageTitle: 'Edit address',
            previousPageTitle: 'Organisation Account',
        },
        accountEditEmail: {
            pageTitle: 'Edit email address',
            previousPageTitle: 'Account Overview',
            successNotification:
                'An email has been sent to {{userEmail}} with instructions on how to verify this email address.',
        },
        accountChangePassword: {
            pageTitle: 'Change Password',
            incorrectPassword: 'The current password is incorrect.',
            rateLimitError:
                "Sorry, you've exceeded the maximum allowed number of attempts to change your password. Please try again after an hour.",
            genericErrorMessage: 'We are unable to reset your password right now, please retry later.',
        },
        accountMFA: {
            pageTitle: 'Two-Factor Authentication',
            previousPageTitle: 'Account Overview',
            info: 'Instead of waiting for text messages, get verification codes from an authenticator app. It works even if your phone is offline. First, download Authenticator app from the Google Play Store or the iOS App Store',
            inputLabel: '6 digit code',
            submit: 'Continue',
        },
        accountEditPaymentTerms: {
            formItemHeader: 'Payment Terms',
            inputLabel: '',
            pageTitle: 'Payment Settings',
            previousPageTitle: 'Organisation Account',
            saveButtonLabel: 'Save changes',
            saveErrorMessage: 'Saving changes failed. Please try again later.',
        },
        accountValidateEmail: {
            emailUpdated: 'Your email address has been updated.',
            unableToUpdateEmail: 'We are currently unable to update your email address, please try again later.',
        },
        addressDetails: {
            additionalInformationLabel: 'Additional information',
            collectionInformationLabel: 'Collection address notes',
            deliveryTimeframeLabel: 'Delivery timeframe',
            deliveryInformationLabel: 'Delivery address notes',
            addressNotLoadable: 'Address could not be loaded.',
            chooseAddressButtonLabel: 'Choose other address',
            deliveryInfoCollectionLabel: 'Collection address',
            sectionHeading: {
                [AddressIdentifier.BILLING_ADDRESS]: 'Billing Address',
                [AddressIdentifier.DELIVERY_ADDRESS]: 'Delivery Address',
                [AddressIdentifier.PICKUP_ADDRESS]: 'Collection Address',
                [AddressIdentifier.DELIVERY_INFO]: 'Delivery Info',
            },
        },
        addUser: {
            headerTitle: 'Add New User',
            backLinkText: 'User Management',
            addUserButtonLabel: 'Create User',
        },
        dashboard: {
            headerTitle: 'Welcome {{name}}!',
            bannerSearch: {
                header: 'Start your search',
                body: 'Search for materials, set an alert and let us find the match you need.',
                buttonLabel: 'Search for material',
            },
            bannerSell: {
                header: 'Sell your products',
                body: 'Sell your products and get an overview of the latest sales status.',
                buttonLabel1: 'Create Listing',
                buttonLabel2: 'My Listings',
            },
            urgentRequests: {
                headerTitle: 'Immediate material request from buyer',
                helperText:
                    "You can create a new listing based on the buyer's immediate material request. Once your listing is live on the marketplace, we will promptly notify the buyer of the new matching results via email.",
                noUrgentRequests: 'There are currently no immediate material requests.',
                viewAll: 'View All',
            },
            bannerRequest: {
                header: 'Request material now',
                body: 'Reach our entire network of sellers and get a response within hours.',
                buttonLabel: 'Request material',
            },
        },
        dashboardRfq: {
            bannerNewAtSteelBuy: {
                header: 'New at SteelBuy: Mill Rollings',
                body: 'Submit your material RFQ directly to many mills and compare quotes before you order.',
                buttonLabel: 'Create RFQ',
            },
        },
        addToCartConfirmModal: {
            headline: 'Added to cart successfully',
            continueShopping: 'Continue shopping',
            viewCart: 'View cart',
            checkoutBtn_one: 'Checkout ({{count}} item)',
            checkoutBtn_other: 'Checkout ({{count}} items)',
            addToCartFailed: 'The item may have expired or been sold and cannot be added to your cart.',
            alreadyInCart: 'This listing is already in the cart.',
            actFast: 'Act fast! Your cart will be automatically emptied in <time>{{time}}</time>',
            actFastSmallScreenModal: 'Cart will be automatically emptied in <time>{{time}}</time>',
            itemsNotReserved: "Items aren't reserved and may be bought by others.",
            itemsNotReservedSmallScreenModal: 'Items may be bought by others.',
            insufficientCredit:
                'Insufficient credit balance to add this item to the cart. Please add funds to proceed.',
        },
        cart: {
            entrySuccessMessage: 'Your listing has been removed from the shopping cart.',
            entryErrorMessage: 'Failed to remove listing from cart. Please try again.',
            expiredMessage: 'Cart has expired.',
            insufficientCreditMessage: `Cannot checkout because some items in your cart can't be purchased due to insufficient credit.`,
        },
        cartReminderNotification: {
            message: 'Cart will be automatically emptied in <time>{{time}} minutes.</time>',
            checkoutButtonLabel_one: 'Checkout ({{count}} item)',
            checkoutButtonLabel_other: 'Checkout ({{count}} items)',
        },
        checkout: {
            cancel: {
                content: 'Your order will be deleted.',
                confirmButtonLabel: 'Got it',
            },
            header: {
                title: 'Checkout',
            },
            checkbox1Label:
                'By ticking this box, I represent that I am authorised to enter into this contract for and on behalf of {{organisationName}}.',
            checkbox2Label:
                'By ticking this box, I agree for and on behalf of {{organisationName}} that I have read and agree to the following terms and conditions: <websitetncs>Website Terms & Conditions,</websitetncs> <buyertncs>Buyers Terms & Conditions</buyertncs>.',
            negotiationExists:
                'Another member from your organisation is currently engaged in negotiations for this item.',
            creditLimitExceeded:
                'The price of this product exceeds your remaining credit line. Need help? Contact support: support@steel-buy.com',
            delivery: {
                title: 'Delivery',
                haulierRequiredMessage: 'Does the haulier need to book this delivery in?',
                checkboxYes: 'Yes',
                checkboxNo: 'No',
                materialLocationLabel: 'Material location',
            },
            purchaseOrderNumber: 'Your Purchase Order Number',
            purchaseOrderPlaceholderText: 'PO3874387',
            purchaseOrderErrorMsg: 'Please enter a valid purchase order number',
            deliveryText: 'Expected delivery time',
            payment: {
                creditInfoNotification: 'Amount is deducted from your credit line.',
                sectionHeading: 'Payment',
                optionCreditLabel: 'Credit',
                optionCreditExplanation: 'Your order will be shipped directly.',
                optionUpfrontLabel: 'Upfront payment',
                optionUpfrontExplanation: 'Your order will be shipped after we receive your payment.',
            },
            priceUpdatedError: 'The listing price has been updated, please review the new price prior to purchase.',
            pricePerUnitInclDelivery: 'Price per {{tradeUnit}} (incl. delivery)',
            shippingCostsLabel: 'Delivery',
            incompleteInformationNotification: 'Please fill in all required information to complete the purchase.',
            submitCheckoutButtonLabel: 'Confirm and Pay',
            totalPriceSectionHeading: 'Weight & Price',
            totalExclVat: 'Total (excl. VAT)',
            itemsTotalExclVat: 'Items Total (excl. VAT)',
            totalInclVat: 'Total (incl. VAT)',
            vatLabel: '+ {{value}}% VAT',
            weight: 'Weight',
            theoreticalWeight: 'Theoretical weight',
            error: {
                pageTitle: 'Sorry. We couldn’t place your order',
                retryMessage:
                    'If the order cannot be placed after retry please contact <link1>support@steel-buy.com</link1> for help.',
                contactMessage:
                    "The order couldn't be placed. Please contact our support team <link1>support@steel-buy.com</link1> for help.",
                contactSupport: 'Contact Support',
                retry: 'Retry',
                link: 'Back to checkout page',
                unableToPurchaseCartWithRetry:
                    'Sorry, we couldn’t place your order. If the order cannot be placed after retry please contact <link1>support@steel-buy.com</link1> for help.',
                unableToPurchaseCartAfterRetryExceeded:
                    'Sorry, we couldn’t place your order. Please contact <link1>support@steel-buy.com</link1> for help.',
                creditLimitExceedContactSupport:
                    'You have exceeded your current credit limit. Please contact <link1>support@steel-buy.com</link1> for help.',
            },
        },
        checkoutAddAddress: {
            errorMessage: 'Failed to add address. Please try again later.',
            pageTitle: 'Add New Address',
            previousPageTitle: 'Choose Address',
        },
        checkoutChooseAddress: {
            pageTitle: 'Checkout',
        },
        checkoutDeliveryOption: {
            headerLabel: 'Delivery Option',
            headerDescriptionLabel: 'Should SteelBuy deliver all items to a single address or multiple addresses?',
            singleAddressLabel: 'Single address',
            singleAddressDescriptionLabel: 'Your order will be shipped to 1 address.',
            multipleAddressLabel: 'Multiple addresses',
            multipleAddressDescriptionLabel: 'Your order will be shipped to multiple addresses.',
            orderSummary: 'Order Summary',
            totalWeight: 'Total weight',
        },
        checkoutSuccess: {
            header: {
                title: 'Checkout',
            },
            successText: 'SteelBuy received your purchase request!',
            orderNumberText: 'SteelBuy order number:',
            purchaseOrderNumber: 'Your purchase order number:',
            steelbuyReferenceNumber: 'SteelBuy’s reference number:',
            emailHint: 'You will receive an email with purchase confirmation, invoice and test certificate.',
            deliveryHint: 'SteelBuy will collect and deliver the material.',
            banner: {
                headline: 'Have any questions about your order?',
                contactText: 'Feel free to contact us here:',
                emailAddress: 'support@steel-buy.com',
            },
            searchListingsButtonLabel: 'Search listings',
            manageOrder: 'Manage your order under <link1>My orders</link1>',
        },
        contactDetails: {
            header: {
                title: 'Contact Us',
            },
        },
        createAlert: {
            title: "Select “immediate material request” when you need to purchase materials immediately and we'll alert our sellers about your request.",
            urgentRequest: 'Yes, immediate material request',
            nonUrgentRequest: 'Not now, normal alert',
        },
        createListingAddPickupAddress: {
            errorMessage: 'Failed to add address. Please try again later.',
            pageTitle: 'Add Warehouse Address',
            previousPageTitle: 'Choose Collection Address',
        },
        createListingOverview: {
            notificationSuccess: 'Your draft has been deleted successfully.',
            notificationError: 'Your draft could not be deleted.',
        },
        createListingListUpload: {
            packageTitleHeader: 'Package title',
            packageTitleLabel: 'The package title for buyers',
            packageTitlePlaceholder: 'e.g. 20 coils, galvanised, hot rolled..',
            packageDescriptionLabel: 'The package description for buyers',
            packageDescriptionHeader: 'Package description',
            packageDescriptionPlaceholder:
                'Please describe your package so that buyers can easily understand what’s inside.',
            packageListUploadHeader: 'Package list upload (Excel File)',
            packageListUploadLabel: 'Upload an Excel file containing all items within the package',
            packageFileInputHelperText: 'Excel file ({{fileType}})',
        },
        createListingChoosePickupAddress: {
            pageTitle: 'Choose Collection Address',
            previousPageTitle: 'Create Listing',
        },
        createListing: {
            draftSuccessfulNotification: 'Your draft has been successfully saved.',
            draftFailedNotification: 'Failed to save listing as draft.',
            packageDraftFailedNotification: 'Failed to save package as draft.',
            headerCreateNew: 'Create Listing',
            headerEditDraft: 'Edit Draft Listing',
            rejectedListingConvertedToDraft:
                'The rejected listing has been converted to a draft listing. It\'s now listed under "Create Listing".',
            targetPriceHeader: 'Target Price',
        },
        createListingDraftNotFound: {
            pageTitle: 'Listing Draft Not Found',
            previousPageTitle: 'Overview',
            notificationMessage:
                'This listing draft could not be found. It might have been deleted or published for review.',
            notificationButtonLabel: 'Create new listing',
        },
        createListingSaveDraftButton: {
            label: 'Save as draft',
        },
        createListingSteps: {
            labels: {
                [Steps.MATERIAL]: 'Materials',
                [Steps.DIMENSIONS]: 'Dimensions',
                [Steps.WEIGHT_PRICE]: 'Weight & Price',
                [Steps.PICKUP_ADDRESS]: 'Collection Address',
                [Steps.LIST_UPLOAD]: 'List Upload',
                [Steps.DELIVERY_INFO]: 'Delivery Info',
            },
        },
        createListingButtons: {
            viewSummaryButtonLabel: 'View summary',
            wizardNextButtonLabel: 'Next',
        },
        createListingSuccess: {
            buttonLabelCreateNew: 'Create new listing',
            buttonLabelCreateSame: 'Create same listing',
            buttonLabelMyListings: 'My listings',
            header: 'Create Listing',
            marketingBannerHeader: 'Have any questions about your order?',
            marketingBannerText: 'Feel free to contact us here:',
            marketingBannerEmail: 'support@steel-buy.com',
            nextStepsHint: 'Next steps',
            nextStepNotification: "You’ll receive a notification when it's live.",
            nextStepReview: 'Our team will review the listing to ensure it meets quality standards.',
            notificationMessage: 'Your listing is under review.',
        },
        createListingSummary: {
            errorNotificationMessage: 'An error occurred, please try again later.',
            expirationNotificationMessage:
                'You commit to sell your material exclusively on SteelBuy for the agreed duration of the listing.',
            header: 'Summary',
            submitButtonLabel: 'Submit listing',
            weightAndPriceHeader: 'Weight & Your Selling Price',
            weightAndPriceNotification: 'We will add a delivery and service fee to your price.',
            weightAndPriceNotificationTheoretical:
                'We will add a delivery and service fee to your price. You will be selling this item based on theoretical weight only.',
            checkboxLabel:
                'By ticking this box, I agree for and on behalf of {{organisationName}} that I have read and agree to the following terms and conditions: <websitetncs>Website Terms & Conditions</websitetncs>, <sellertncs>Sellers Terms & Conditions</sellertncs>',
            websiteTCLinkText: 'Website Terms & Conditions',
            sellerTCLinkText: 'Sellers Terms & Conditions',
        },
        createListingUnsavedChangesModal: {
            discardButtonLabel: 'Discard changes',
            headline: 'This listing has unsaved changes.',
            savingFailedNotification: 'Saving failed, please try again later.',
        },
        createRFQUnsavedChangesModal: {
            headline: 'This RFQ has unsaved changes.',
        },
        createRFQ: {
            headerCreateNew: 'Create RFQ',
            headerEditDraft: 'Edit Draft RFQ',
            submitButtonLabel: 'Submit RFQ',
            chooseMaterial: 'Choose your desired material',
            addItems: 'Add items to your Request for Quote (RFQ)',
            chooseMaterialOrigin: 'Choose preferred material origin and required delivery month',
            wizardNextButtonLabel: 'Next',
            viewSummary: 'View Summary',
            comments: 'Comments',
            selectPlaceholder: 'Choose an option',
            addSimilar: 'Add similar',
            actions: 'Actions',
            addToRFQ: 'Add to RFQ',
            saveAsDraft: 'Save as draft',
            deleteRFQItemConfirmation: 'Are you sure you want to delete this item?',

            materialOrigin: {
                header: 'Preferred Material Origin(s)',
                description: 'Please select one or multiple origins. (Western European: EU, UK, Switzerland, Norway)',
            },
            preferredOrigin: 'Preferred origin(s)',
            deliveryMonth: {
                header: 'Required Delivery Month',
                description: 'Please select your required delivery month from the dropdown.',
                subHeader: 'Required delivery month',
            },
            fieldRequired: 'This field is required.',
            quoteDeadline: {
                header: 'Quote Deadline',
                subHeader: 'Quote deadline',
                description:
                    'Please specify the latest date by which you need to receive quotes from suppliers. The quote will expire at the end of your selected date.',
            },
            additionalComments: {
                header: 'Additional Comments',
                description:
                    'Please provide additional comments regarding the desired quality of materials for the RFQ.',
            },
            rfqDetails: 'RFQ Details',
            downloadRfq: 'Download RFQ',
            rfqReferenceNumber: 'RFQ reference number',
        },
        myRFQ: {
            header: 'My RFQs',
            submitted: 'Submitted',
            drafts: 'Drafts',
            noResultsDescription: 'Create new RFQ of material you want to buy.',
            noListingsCallToAction: 'Create RFQ',
            numberOfRFQListings_zero: 'No RFQs',
            numberOfRFQListings_one: '{{count}} RFQ',
            numberOfRFQListings_other: '{{count}} RFQs',
            notificationMsg:
                'The suppliers can review the product list and reply with quotation. You can select accept or reject the quotation.',
            publishRfq: 'PUBLISH RFQ',
            createSimilarRFQ: 'Create similar RFQ',
            statusText: {
                header: {
                    [RFQStatus.UNDER_REVIEW]: 'Submitted for review at:',
                    [RFQStatus.REJECTED]: 'Rejected at:',
                    [RFQStatus.LIVE]: 'Published at:',
                    [RFQStatus.WITHDRAWN]: 'Withdrawn at:',
                    [RFQStatus.EXPIRED]: 'Expired at:',
                },
            },
            emailSubject: 'Rejection of RFQ {{quoteRef}}',
        },
        myRFQDetails: {
            withdrawRfq: 'Withdraw RFQ',
            withdrawSuccess: 'Your RFQ has been withdrawn.',
            withdrawFailure: 'Unable to withdraw your RFQ. Please try again later.',
            withdrawModal: {
                header: 'Are you sure you want to withdraw your RFQ?',
                confirmLabel: 'Withdraw RFQ',
            },
            updateFailure: 'Unable to Publish your RFQ. Please try again later.',
            success: 'Your RFQ is live.',
            notificationMsg:
                'You can renew this listing by setting a new quote deadline, which will allow you to publish the renewed RFQ directly to the sellers. You can also adjust your RFQ details, if necessary, by clicking Create Similar RFQ.',
            restartRFQSuccess: `The rejected RFQ has been converted to a draft RFQ. It's now listed under "My RFQs".`,
            restartRFQFailure: 'Failed to convert to Draft RFQ. Please try again later.',
        },
        myRFQDraft: {
            numberOfRFQDraftListings_zero: 'No Drafts',
            numberOfRFQDraftListings_one: '{{count}} Draft',
            numberOfRFQDraftListings_other: '{{count}} Drafts',
        },
        myRFQFilter: {
            selectLabel: 'Filter by',
            selectPlaceholder: 'Choose options',
            optionLabels: {
                [RFQStatus.REJECTED]: 'Rejected',
                [RFQStatus.LIVE]: 'Live',
                [RFQStatus.WITHDRAWN]: 'Withdrawn',
                [RFQStatus.EXPIRED]: 'Expired',
                [RFQStatus.UNDER_REVIEW]: 'Under review',
            },
        },
        myCart: {
            header: 'Shopping Cart',
            infoMsg: "Items aren't reserved and may be bought by others.",
            noItemCallToAction: 'Search listings',
            noItemCaption: 'Cart is empty',
            noResultsDescription: 'Search and buy listings now.',
            itemsExpiredOrSold:
                'Some items may have expired or been sold. Your shopping cart has been updated accordingly.',
        },
        createRFQSteps: {
            labels: {
                [RFQStep.CHOOSE_MATERIALS]: 'Choose Materials',
                [RFQStep.ADD_ITEMS]: 'Add Items',
                [RFQStep.SHIPPING_DETAILS]: 'Shipping Details',
            },
        },
        createRFQSuccess: {
            buttonLabelCreateNew: 'Create RFQ',
            buttonLabelMyListings: 'My RFQs',
            header: 'Create RFQ',
            marketingBannerHeader: 'Have any questions about your RFQ?',
            marketingBannerText: 'Feel free to contact us here:',
            marketingBannerEmail: 'support@steel-buy.com',
            nextStepsHint: 'Next steps',
            nextStepNotification: "You’ll receive a notification when it's live.",
            nextStepReview: 'Our team will review the RFQ to ensure it meets quality standards.',
            notificationMessage: 'Your RFQ is under review.',
            referenceNumber: 'RFQ reference number: ',
        },
        createRFQSummary: {
            submitButtonLabel: 'Submit RFQ',
            header: 'Summary',
            createRFQFailed: 'An error occurred, please try again later.',
        },
        createDraftRFQ: {
            createDraftRFQFailed: 'An error occurred, please try again later.',
            createDraftRFQSuccess: 'Your draft has been successfully saved.',
        },
        addRfq: {
            addTitle: 'Add New Item',
            addDescription:
                "To add an item, please complete all fields in the row. Once filled, click the 'Add to RFQ' button to proceed.",
            itemExists: 'Item already exists. Please amend.',
            genericError: 'Invalid form values.',
        },
        legalCheckboxes: {
            checkbox1Label:
                'By ticking this box, I represent that I am authorised to enter into this contract for and on behalf of {{organisationName}}.',
            checkbox2Label:
                'By ticking this box, I agree for and on behalf of {{organisationName}} that I have read and agree to the following terms and conditions: <websitetncs>Website Terms & Conditions</websitetncs>, <sellertncs>Sellers Terms & Conditions</sellertncs>',
        },
        editUser: {
            headerTitle: 'Edit User',
            backLinkText: 'User Management',
            saveUserButtonLabel: 'Save changes',
            toggleUserSuccess: {
                enabled: 'User has successfully been reactivated',
                disabled: 'User has successfully been deactivated',
            },
            toggleUserError: {
                enabled: 'Failed to deactivate user. Please try again later.',
                disabled: 'Failed to reactivate user. Please try again later.',
            },
        },
        footer: {
            termsLinkText: 'Terms & Conditions',
            contactDetailsLinkText: 'Contact Us',
            privacyPolicyLinkText: 'Privacy Policy',
            acceptableUseLinkText: 'Acceptable Use Policy',
        },
        genericError: {
            dashboardButtonLabel: 'Go to Dashboard',
            helperText: 'Ooops! Something went wrong. Please go back or try to reload this page.',
            reloadButtonLabel: 'Reload',
        },
        legal: {
            headerTitle: 'Legal Notes',
        },
        listingDetails: {
            detailedDescription: 'Detailed Description',
            testCertificateTitle: 'Test Certificate',
            deliveryTitle: 'Delivery',
            deliveryText: 'Expected delivery time',
            checkoutButtonText: 'Buy Now',
            offerButtonText: 'Make an offer',
            packageListSheetTitle: 'Package List Spreadsheet',
        },
        listingNotFound: {
            notFoundMessage: 'Sorry, this product is no longer available.',
            newSearchMessage: 'Maybe a similar product is already waiting for you?',
            newSearchButton: 'Start new search',
        },
        listingSearchForm: {
            headerTitle: 'Search Listings',
            theoreticalWeightNotification: 'Maximum possible theoretical weight.',
        },
        ListingSearchFormInputs: {
            refineSearch: 'Refine your search',
            incompleteInformationNotification:
                'Please fill in all required information to submit your material request.',
            submitText: 'Search',
            weightLabel: 'Required weight',
            theoreticalWeightLabel: 'You will be notified of material based on theoretical weight only.',
            targetPriceHeader: 'Desired Target Price',
            targetPriceDescription: 'Please consider that offers far below market value may be overlooked by sellers.',
            targetPriceLabel: 'Desired target price (optional)',
            minimumTargetPriceError: 'Please enter a higher amount to match the market value',
            numberOfItems: {
                [Product.SHEET]: 'Required number of sheets',
                [Product.PLATE]: 'Required number of plates',
                [Product.TREAD]: 'Required number of tread plates',
            },
        },
        listingAlertNotification: {
            explanation: 'Create an alert, and we will notify you about new matching results via email. ',
            createSuccess: 'You have created an alert. ',
            deleteSuccess: 'Your alert has been deleted.',
            link: 'You can find your alerts under My Alerts.',
        },
        listingSearchResults: {
            addAlertButtonLabel: 'Create alert',
            deleteAlertButtonLabel: 'Delete alert',
            header: {
                title: 'Search Results',
                backLinkText: 'Search Criteria',
            },
            editSearchButtonTitle: 'Edit search',
            noResultsText: 'Currently there are no results for your search.',
            noResultsButtonLabel: 'Edit search criteria',
        },
        locationAwareGenericError: {
            pageTitle: 'Something went wrong',
        },
        materialStep: {
            anonymityBannerHeader: 'Anonymity is important to us!',
            anonymityBannerText: 'Your certificate will be anonymised by SteelBuy.',
            missingProductsNotification: 'Not finding the product you are looking for?',
            missingProductsLinkLabel: 'Notify us!',
            missingProductsEmailSubject: 'Missing product',
        },
        myAccount: {
            myAccountCardHeader: 'My Account',
            myAccountCardEmailLabel: 'Email',
            myAccountCardNameLabel: 'Name',
            editEmailAddress: 'Edit email address',
            passwordCardButtonLabel: 'Update password',
            passwordCardHeader: 'Password',
            permissionCardHeader: 'My Permissions',
            mfa: {
                header: 'Two-Factor Authentication',
                button: 'Change settings',
                enabled: 'Enabled',
                disabled: 'Disabled',
            },
        },
        myAlertsOverview: {
            deleteSuccess: 'Your alert has been deleted.',
            pageHeader: 'My Alerts',
            pageHeaderButton: 'Start new search',
        },
        myOffers: {
            pageHeader: 'My Offers',
            dataHeader: {
                numberOfOffers_zero: 'No Offers',
                numberOfOffers_one: '{{count}} Offer',
                numberOfOffers_other: '{{count}} Offers',
            },
            noResultsText: 'There are currently no offers.',
            infoMsg:
                'The seller can review the proposed price and decides to accept, reject, or counter the offer. If no action is taken before the date and time, the negotiation will be cancelled automatically.',
        },

        myAlertsSearchResults: {
            pageHeader: 'Results',
            previousPageTitle: 'My Alerts',
            notificationIllustratedHeader: "It's a match",
            notificationIlulustratedHelperText: 'Have a look at the new listings that match your search',
            searchSummaryNewResults: '{{numListings}} new',
        },
        myAlertsSettings: {
            changesSavedNotificationMessage: 'Your changes have been saved!',
            deleteButtonLabel: 'Delete alert',
            header: 'Alert Settings',
            headerBackLinkLabel: 'My Alerts',
            mailNotificationHeader: 'E-mail notifications',
            mailNotificationLabel:
                'You will receive an email notification to {{email}} as soon as a product matches your alert.',
            receiveNotifications: 'Receive notifications',
            saveButtonLabel: 'Save changes',
        },
        myListings: {
            header: 'My Listings',
            noResultsDescription: 'Create new listing for material you want to sell.',
            noListingsCallToAction: 'Create listing',
            numberOfListings_zero: 'No Listings',
            numberOfListings_one: '{{count}} Listing',
            numberOfListings_other: '{{count}} Listings',
            numberOfDrafts_zero: 'No Drafts',
            numberOfDrafts_one: '{{count}} Draft',
            numberOfDrafts_other: '{{count}} Drafts',
            listings: 'Listings',
            drafts: 'Drafts',
            subTabListingLabel: 'Main Listing',
            subTabDraftsLabel: 'Main Drafts',
        },
        myPackages: {
            numberOfPackageListings_zero: 'No Package Listings',
            numberOfPackageListings_one: '{{count}} Package Listing',
            numberOfPackageListings_other: '{{count}} Package Listings',
            numberOfPackageDrafts_zero: 'No Drafts',
            numberOfPackageDrafts_one: '{{count}} Package Draft',
            numberOfPackageDrafts_other: '{{count}} Package Drafts',
            subTabPackageLabel: 'Package Listings',
            subTabDraftsLabel: 'Package Drafts',
        },
        myListingsFilter: {
            selectLabel: 'Filter by',
            selectPlaceholder: 'Choose options',
            optionLabels: {
                [ListingStatus.DRAFT]: 'Draft',
                [ListingStatus.REVIEW_REQUIRED]: 'Under review',
                [ListingStatus.REVIEW_REJECTED]: 'Rejected',
                [ListingStatus.PUBLISHED]: 'Live',
                [ListingStatus.PURCHASED]: 'Sold',
                [ListingStatus.WITHDRAWN]: 'Withdrawn',
                [ListingStatus.EXPIRED]: 'Expired',
            },
        },
        manageOffers: {
            pageHeader: 'Manage Offers',
            dataHeader: {
                numberOfOffers_zero: 'No Offers',
                numberOfOffers_one: '{{count}} Offer',
                numberOfOffers_other: '{{count}} Offers',
            },
            noResultsText: 'There are currently no offers.',
            infoMsg:
                'The buyer can review the proposed price and decides to accept, reject, or counter the offer. If no action is taken before the date and time, the negotiation will be cancelled automatically.',
        },
        offerListingsFilter: {
            selectLabel: 'Filter by',
            selectPlaceholder: 'Choose options',
            awaitingYourResponse: 'Awaiting your response',
            optionLabels: {
                [OfferListingStatus.AWAITING_BUYER]: "Awaiting buyer's response",
                [OfferListingStatus.AWAITING_SELLER]: "Awaiting seller's response",
                [OfferListingStatus.ACCEPTED]: 'Accepted',
                [OfferListingStatus.REJECTED]: 'Rejected',
                [OfferListingStatus.WITHDRAWN]: 'Withdrawn',
                [OfferListingStatus.EXPIRED]: 'Expired',
                [OfferListingStatus.CANCELLED]: 'Cancelled',
                [OfferListingStatus.PURCHASED]: 'Sold',
            },
        },
        offers: {
            negotiationsHeader: 'You are negotiating on this listing',
            accordionHeader: 'Negotiation reference number: {{referenceId}}',
            timeLeft: 'Time left to take action',
            tableHeadings: {
                offersAndCounterOffers: 'Offer(s) and counter offer(s)',
                date: 'Date',
                requestBy: 'Request by',
                action: 'Action',
            },
            reject: 'Reject',
            counter: 'Counter',
            accept: 'Accept',
            withdraw: 'Withdraw offer',
            hours: 'hours',
            minutes: 'minutes',
            acceptModelHeader: 'Are you sure you want to accept this offer?',
            buyNowModalheader:
                'Existing offer in progress. Are you sure you want to proceed with the purchase at the original price?',
            addToCartModalHeader:
                'Existing offer in progress. Are you sure you want to add the listing to cart at the original price?',
            buynow: 'Buy now',
            submitCounterOffer: 'Submit counter offer',
            review: 'Review',
            priceExceed: 'The amount you entered is higher than original price.',
            minimumPriceError: 'Please enter a higher amount to match the market value.',
            limitPriceExceed: 'The amount you entered exceeds your credit limit.',
            buyerCounterInfoMsg: 'We will send you a response from buyer within {{timeLeft}}',
            sellerCounterInfoMsg: 'We will send you a response from seller within {{timeLeft}}',
            counterOffer: 'Your counter offer',
            reviewCounterOffer: 'Review counter offer',
            pricelabel: 'Original price per {{pricingUnit}}',
            buyerOfferedPriceLabel: "Buyer's offer per {{pricingUnit}}",
            sellerOfferedPriceLabel: "Seller's offer per {{pricingUnit}}",
            proposedPrice: 'Your proposed price per {{pricingUnit}}',
            total: 'Total',
            reviewPricingUnit: {
                [PricingUnit.TON]: {
                    label: 'Weight',
                },
                [PricingUnit.KILOGRAM]: {
                    label: 'Weight',
                },
                [PricingUnit.SHEET]: {
                    label: 'Items',
                },
                [PricingUnit.PLATE]: {
                    label: 'Items',
                },
                [PricingUnit.TREAD]: {
                    label: 'Items',
                },
            },
            rejectModelHeader: 'Are you sure you want to reject this offer?',
            withdrawModalHeader: 'Are you sure you want to withdraw your offer?',
            showAllText: 'Show all counter offers record',
            counterMsgSeller: 'Your counter offer has been received. You can expect a response from the buyer shortly.',
            counterMsgBuyer: 'Your offer has been received. You can expect a response from the seller shortly.',
            counterErrorMSg: 'Currently unable to counter the offer, please try again.',
            acceptErrorMsg: 'Currently unable to accept the offer, please try again.',
            rejectErrorMsg: 'Currently unable to reject the offer, please try again.',
            withdrawErrorMsg: 'Currently unable to withdraw the offer, please try again.',
            acceptMsgBuyer:
                "You've accepted the seller's offer. Complete your purchase within the next 24 hours to secure your item.",
            rejectMsgBuyer: 'Your offer rejection is confirmed.',
            withdrawMsg: 'Your offer has been withdrawn.',
            acceptMsgSeller:
                'The seller has accepted the offer, which will be available until the request time period ends.',
            rejectMsgSeller: 'The seller has rejected the offer.',
            notificationMsgBuyer:
                'The seller can review the proposed price and decides to accept, reject, or counter the offer. If no action is taken before the date and time, the negotiation will be cancelled automatically.',
            notificationMsgSeller:
                'The buyer can review the proposed price and decides to accept, reject, or counter the offer. If no action is taken before the date and time, the negotiation will be cancelled automatically.',
        },
        myOrders: {
            header: 'My Orders',
            numberOfOrders_zero: 'No Orders',
            numberOfOrders_one: '1 Order',
            numberOfOrders_other: '{{count}} Orders',
            noResultsDescription: 'No results match these filter options.',
            noOrdersCallToAction: 'Clear filters',
            bannerBuy: {
                header: 'Buy your first product',
                subHeader: 'Buy material and benefit from our SteelBuy promise:',
                description: [
                    'Instant access to materials to maximise your time.',
                    'The fastest way to buy steel, where technology does the work.',
                    'Fully managed order process.',
                ],
                buttonLabel: 'Search for material',
            },
        },
        myOrdersFilter: {
            selectLabel: 'Filter by',
            selectPlaceholder: 'Choose an option',
        },
        myOrderDetails: {
            marketingBannerHeader: 'Have any questions about your order?',
            marketingBannerText: 'Feel free to contact us here:',
            purchaseOrderNumbersHeader: 'Purchase Order Numbers',
            purchaseOrderNumberHeader: 'Purchase Order Number',
            steelbuyPurchaseOrderNumberLabel: 'SteelBuy Purchase Order No',
            buyerPurchaseOrderNumberLabel: 'Your Purchase Order No',
            backLinkText: 'My Orders',
            steelBuyReferenceNoLabel: 'SteelBuy Reference No',
        },
        myOrderDetailsNotFound: {
            pageTitle: 'Order Not Found',
            previousPageTitle: 'My Orders',
            notFoundMessage: 'This order could not be found.',
        },
        myOrderDetailsStatus: {
            cardHeader: 'Order Status',
            delivered: 'Delivered',
            delivery: 'Delivery',
            freight_booked: 'Freight booked',
            processing: 'Processing order',
            processed: 'Order processed',
            shipping: 'Shipping',
            shippingConfirmed: 'Shipping confirmed',
        },
        offerSuccess: {
            title: 'Your Offer',
            successText: 'SteelBuy received your offer!',
            referenceNumberText: 'SteelBuy reference number:',
            responseHint: 'You will receive a response from the seller on your offer soon.',
            manageOffers: 'Manage your offers under <link1>My Offers</link1>.',
        },
        pickupAddressStep: {
            anonymityBannerHeader: 'Anonymity is important to us!',
            anonymityBannerText: 'Your address is required for pickup and is only visible to SteelBuy.',
            incompleteInformationNotification: 'Please provide complete information to proceed to summary.',
            filesUploadingNotification: 'Please wait for files to finish uploading.',
            additionalInformationLabel: 'Collection address notes',
            additionalInfoPlaceholder: 'e.g. parking instructions, contact information for warehouse personnel, etc.',
            deliveryTimeframeHeader: 'Delivery Timeframe',
            deliveryTimeframeLabel: 'Your delivery timeframe of the current package.',
        },
        privacyPolicy: {
            headerTitle: 'Privacy Policy',
            cookieLinks: {
                edge: 'https://enablecookies.info/#en-edge',
                safari: 'https://enablecookies.info/#en-safari',
                chrome: 'https://enablecookies.info/#en-chrome',
                firefox: 'https://enablecookies.info/#en-firefox',
                opera: 'https://enablecookies.info/#en-opera',
            },
        },
        purchaseContactEdit: {
            backLinkText: 'Organisation Account',
            emailFieldLabel: 'Email Address',
            headerTitle: 'Purchase Contact',
            nameFieldLabel: 'Name',
            notificationErrorMessage: 'Saving failed please try again later',
            saveButtonLabel: 'Save changes',
        },
        renewListingSuccess: {
            notificationMessage: 'Your listing is live!',
            notificationHelperText: 'SteelBuy listing ID: {{steelbuyListingID}}',
            bannerHeader: 'Have any questions about your listing?',
            bannerText: 'Feel free to contact us here:',
            bannerEmailAddress: 'support@steel-buy.com',
            myListingsButtonLabel: 'My listings',
        },
        rfqHeadingLabels: {
            grade: 'Grade',
            specification: 'Specification',
            finish: 'Finish',
            width: 'Width',
            thickness: 'Thickness',
            length: 'Length',
            weight: 'Weight',
            tolerance: 'Tolerance',
            surface: 'Surface',
            coating: 'Coating',
            coatingThicknessValue: 'Coating thickness',
        },
        sellerListingDetails: {
            rejectionReason: {
                title: 'Rejection Reason',
                description: 'Contact support if you have questions about the review process.',
                buttonLabel: 'Contact Support',
                emailSubject: 'Rejection of listing {{sellerSku}}',
            },
            weightAndSellingPrice: {
                title: 'Weight & Price',
                offerTitle: 'Weight & Your Selling Price',
                weight: 'Original Weight & Price',
                notification: 'We will add a delivery and service fee to your price.',
                offerNotification: "We will add a delivery fee and a service fee to the buyer's total price.",
                newSellingPriceHeader: 'Your new selling price per {{tradeUnit}}',
            },
            checkboxLabel:
                'By ticking this box, I agree for and on behalf of {{organisationName}} that I have read and agree to the following terms and conditions: <websitetncs>Website Terms & Conditions</websitetncs>, <sellertncs>Sellers Terms & Conditions</sellertncs>.',
            createSameButtonLabel: 'Create similar listing',
            renewButtonLabel: 'Publish to marketplace',
            save: 'Save',
            listingHasUnsavedChanges: 'This listing has unsaved changes.',
            updateError: 'Failed to save changes to listing.',
            updateSuccess:
                'Your updates have been successfully saved and will be reflected on the marketplace once your current listing expires and is automatically renewed.',
        },
        sellerListingDetailsNotFound: {
            pageTitle: 'Listing Not Found',
            previousPageTitle: 'My Listings',
            notFoundMessage: 'This listing could not be found. It might have been converted to a listing draft.',
        },
        termsAndConditions: {
            pageHeader: 'Terms and Conditions',
        },
        navigation: {
            itemLabels: {
                account: 'Account',
                alerts: 'My Alerts',
                createListing: 'Create Listing',
                dashboard: 'Dashboard',
                manageOffers: 'Manage Offers',
                myListings: 'My Listings',
                myOffers: 'My Offers',
                myOrders: 'My Orders',
                searchListing: 'Search Listings',
                requestMaterial: 'Request Material',
                activeRequests: 'Active Requests',
                createRfq: 'Create RFQ',
                myRfqs: 'My RFQs',
            },
            menuButtonLabel: 'Menu',
        },
        navigationItemUser: {
            logoutButtonLabel: 'Logout',
        },
        navigationFooter: {
            loginButtonLabel: 'Login',
        },
        warehouseAddressSelection: {
            chooseAddressButtonLabel: 'Choose address',
            addNewAddress: 'Add new address',
        },
        organisation: {
            addressAddedNotificationText:
                "Thank you for adding a new warehouse address. Our operations team will validate the new address before it becomes visible in your warehouse address list. Rest assured, we're on it!",
            addressDeletedNotificationText: 'Address has been deleted.',
            addressUpdatedNotificationText: 'Address has been updated.',
            createNewButtonLabel: 'Add new address',
            creditCardHeader: 'Credit',
            creditLineBalanceLabel: 'Remaining credit balance',
            creditLineTotalLabel: 'Total credit balance',
            invoiceAddressCardHeader: 'Invoice Address',
            organisationCardHeader: 'Organisation',
            organisationCardNameLabel: 'Name',
            organisationSavedNotificationText: 'Changes have been saved successfully!',
            paymentSectionHeading: 'Payment',
            paymentTermsHeader: 'Purchasing Payment Terms',
            primaryAddressTag: 'Primary',
            purchaseContactCardHeader: 'Purchase Contact',
            purchaseContactNameLabel: 'Name',
            purchaseContactEmailLabel: 'Email',
            warehouseAddressHeader: 'Warehouse Address {{number}}',
            warehouseAddressesSectionHeading: 'Warehouse addresses',
        },
        pageTitle: 'SteelBuy Marketplace',
        urgentRequests: {
            pageHeader: 'Immediate Material Request',
            settingsButtonLabel: 'Settings',
            newResultsSeparatorHeader_zero: 'No New Results (Last 24 Hours)',
            newResultsSeparatorHeader_one: '1 New Result (Last 24 Hours)',
            newResultsSeparatorHeader_other: '{{count}} New Results (Last 24 Hours)',
            emptyStateDescription: 'There are currently no immediate material requests.',
            createListing: 'Create Listing',
            qna: {
                header: 'Questions & Answers',
                items: {
                    q1: {
                        label: 'How do immediate material requests work?',
                        content:
                            "If you're a seller, you can create a new listing based on the buyer's immediate material request. Once your listing is live on the marketplace, we will promptly notify the buyer of the new matching results via email. If you're a buyer, you can search for material and turn on immediate material requests, so we can notify you via email as soon as a matching material appears on SteelBuy. In \"My Alerts\" you can manage your notified searches. ",
                    },
                    q2: {
                        label: 'How will I be notified?',
                        content:
                            "If immediate material requests shows up on SteelBuy, we'll send a notification email you to your account's email address.",
                    },
                    q3: {
                        label: 'How often will I be notified',
                        content:
                            'Currently, we notify you by email every time there is a new search result for your criteria. We also allow you to be able to specify the preferred frequency of the notifications.',
                    },
                    q4: {
                        label: 'How can I mute immediate material requests?',
                        content:
                            'You can mute or delete your immediate material requests alert in the settings of immediate material request.',
                    },
                },
            },
            new: 'new',
        },
        urgentRequestsSettings: {
            pageHeader: 'Immediate Material Request Settings',
            headerBackLinkLabel: 'Immediate Material Request',
            changesSavedNotificationMessage: 'Your changes have been saved!',
            mailNotificationHeader: 'E-mail Notifications',
            mailNotificationLabel:
                'You will receive an email notification to {{email}} once an immediate material request has been created (depending on your notification frequency).',
            receiveNotifications: 'Receive notifications',
            saveButtonLabel: 'Save changes',
            notificationFrequencyHeader: 'Notification Frequency',
            notificationFrequencyLabel: 'You will receive notification based on your frequency selection',
        },
        urgentRequestForm: {
            pageHeader: 'Your Immediate Material Request',
            warningMessage: 'Please select and enter the detailed criteria to create an immediate material request.',
            infoMessage: 'Please complete the required fields to create an immediate material request.',
            submitButtonLabel: 'Submit request',
            createdAlertMessageNoMatch: 'You have created an immediate material request.',
            createdAlertMessageWithMatch: 'You have created an immediate material request and found a match.',
            failedToCreate: 'We are currently unable to create your alert, please try again later.',
        },
        userForm: {
            nameFieldLabel: 'Name',
            permissionsFieldLabel: 'Permissions',
            emailFieldLabel: 'Email Address',
            activateToggleLabel: 'Active User',
            deactivateUserModal: {
                modalTitle: 'Are you sure you want to deactivate this user?',
                confirmButtonLabel: 'Yes, deactivate them!',
            },
            activateUserModal: {
                modalTitle: 'Are you sure you want to reactivate this user?',
                confirmButtonLabel: 'Yes, reactivate them!',
            },
            errors: {
                invalidDomain: 'Email must be from same domain as organisation',
                other: 'Error',
            },
        },
        userManagement: {
            emailHeader: 'Email',
            permissionsHeader: 'Permissions',
            createUserSuccess: 'The user has been created!',
            updateUserSuccess: 'The user has been updated!',
        },
        time: {
            day_one: '1 day',
            day_other: '{{count}} days',
        },
    },
    error: {
        noUserError: 'No user loaded',
    },
};
