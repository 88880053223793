import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';
import { setHideCartExpiryToast, useCartDetails } from '@steelbuy/data-access';
import { CartBuyerModel } from '@steelbuy/domain-model';
import { Modal, ModalAccessor } from '@steelbuy/modal-dialog';
import { CartSummary } from '@steelbuy/ui-domain';
import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { ActFastNotification } from '../act-fast-notification/ActFastNotification';

import './AddToCartConfirmModal.scss';

interface AddToCartConfirmModalProps {
    modalAccessor: ModalAccessor;
    cartData: CartBuyerModel;
}

export const AddToCartConfirmModal = ({ modalAccessor, cartData }: AddToCartConfirmModalProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    const { data: cart } = useCartDetails({ queryOptions: { enabled: false } });
    const location = useLocation();
    const navigate = useNavigate();
    const fromSearchResults = location.state?.fromSearchResults || false;

    useEffect(() => {
        setHideCartExpiryToast(modalAccessor.visible);
        return () => {
            setHideCartExpiryToast(false);
        };
    }, [modalAccessor.visible]);

    const onClose = () => {
        modalAccessor.hide();
    };

    const handleContinueShopping = () => {
        if (fromSearchResults) {
            navigate(-1);
        }
        onClose();
    };

    return (
        <Modal modalAccessor={modalAccessor}>
            <div className="add-to-cart-modal">
                <ModalContent onCloseModal={onClose} headline={t('application.addToCartConfirmModal.headline')}>
                    <ModalContentMain>
                        <CartSummary cartData={cartData.cartEntries?.[0]} />
                        <ActFastNotification expiresAt={cartData?.expiresAt?.value} modalSmallScreenNotification />
                        {cart?.status === 'EXPIRED' && (
                            <Notification
                                className="add-to-cart-modal__expired-notification"
                                level={NotificationLevel.WARNING}
                                message={t('application.cart.expiredMessage')}
                            />
                        )}
                    </ModalContentMain>
                    <ModalContentActionBar>
                        <ButtonTertiary
                            label={t('application.addToCartConfirmModal.continueShopping')}
                            onClick={handleContinueShopping}
                        />
                        <ButtonSecondary
                            label={t('application.addToCartConfirmModal.viewCart')}
                            onClick={() => navigate(RoutePath.MY_CART)}
                        />
                        <ButtonPrimary
                            label={t('application.addToCartConfirmModal.checkoutBtn', {
                                count: cart?.cartEntries.length,
                            })}
                            data-testid="checkout-btn"
                            onClick={() => navigate(RoutePath.MY_CART_CHECKOUT)}
                            disabled={!cart?.cartEntries.length}
                        />
                    </ModalContentActionBar>
                </ModalContent>
            </div>
        </Modal>
    );
};
